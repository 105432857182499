import React from "react";
import { styled } from "styled-components";
import { colors } from "./colors";
import { queries } from "./queries.js";

const HeadingProps = styled.h1`
  font-family: "Charm", san-serif;
  font-size: 42px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: unset;
  color: ${colors.brown};
  z-index: 2;

  @media ${queries.tablet} {
    font-size: 56px;
  }

  @media ${queries.laptop} {
    font-size: 72px;
  }
`;

const SectionTitleProps = styled.h2`
  position: relative;
  font-family: "Charm", san-serif;
  font-size: 24px;
  font-weight: bold;
  color: ${colors.brown};
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 15px;
  z-index: ${(props) => (props.$index ? props.$index : 2)};

  @media ${queries.tablet} {
    font-size: 56px;
  }

  @media ${queries.laptop} {
    font-size: 72px;
  }
`;

const DecoratedSectionTitleProps = styled.p`
  font-family: "GreatVibes", san-serif;
  font-size: 40px;
  font-weight: 700;
  color: ${colors.brown};
  letter-spacing: 0.9px;
  text-transform: ${(props) => (props.uppercase ? "uppercase" : "unset")};
  text-align: ${(props) => (props.centered ? "center" : "unset")};
  z-index: 2;

  @media ${queries.laptop} {
    font-size: 32px;
  }
`;

const TextProps = styled.p`
  position: relative;
  font-family: "Caudex", san-serif;
  font-size: ${(props) => (props.size ? props.size : "14px")};
  font-style: ${(props) => (props.$decoration ? props.$decoration : "unset")};
  font-weight: ${(props) => (props.$weight ? props.$weight : "normal")};
  color: ${colors.brown};
  letter-spacing: 0.9px;
  text-transform: unset;
  z-index: ${(props) => (props.$index ? props.$index : 1)};

  @media ${queries.tablet} {
    font-size: 20px;
  }
`;

const SpanProps = styled.span`
  font-style: ${(props) => (props.$decoration ? props.$decoration : "unset")};
  font-weight: 700;
  color: ${(props) => (props.color ? props.color : colors.brownlight)};
  letter-spacing: 0.9px;
  margin-bottom: 0px;
`;

export function Heading({ text }) {
  return <HeadingProps>{text}</HeadingProps>;
}

export function SectionTitle({ text, $index }) {
  return <SectionTitleProps $index={$index}>{text}</SectionTitleProps>;
}

export function SubHeading({ text }) {
  return <DecoratedSectionTitleProps>{text}</DecoratedSectionTitleProps>;
}

export function Text({
  size,
  $weight,
  children,
  uppercase,
  $decoration,
  centered,
  style,
  $index,
}) {
  return (
    <TextProps
      size={size}
      $weight={$weight}
      uppercase={uppercase}
      $decoration={$decoration}
      $index={$index}
    >
      {children}
    </TextProps>
  );
}

export function Span({ color, text, $decoration, ...props }) {
  return (
    <SpanProps color={color} $decoration={$decoration}>
      {text}
    </SpanProps>
  );
}
