import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import { Slide } from "react-awesome-reveal";
import { colors } from "../../utils/colors.js";
import { Text } from "../../utils/typography.js";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../img/ewa_nazim_white_logo.png";

const CopyrightWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const TextWrapper = styled.div`
  background-color: ${colors.mint};
  padding: 15px 0;
  text-align: center;
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
const Logo = styled.img`
  height: 55px;
  width: auto;
`;

const Copyright = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  return (
    <CopyrightWrapper>
      <Slide triggerOnce direction="up" delay={400}>
        <TextWrapper>
          <Container>
            <Row>
              <Col xs={12}>
                <DataWrapper>
                  <Text $weight={700}>{t("footer.copyright")}</Text>
                  <Logo src={logo} alt="logo" />
                  <Text $weight={700}>{currentYear}</Text>
                </DataWrapper>
              </Col>
            </Row>
          </Container>
        </TextWrapper>
      </Slide>
    </CopyrightWrapper>
  );
};

export default Copyright;
