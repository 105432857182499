import React from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { Slide } from "react-awesome-reveal";
import { Container, Row, Col } from "react-bootstrap";
import { queries } from "../../utils/queries.js";
import { colors } from "../../utils/colors.js";
import { SectionTitle, Text } from "../../utils/typography.js";
import CustomButton from "../atoms/CustomButton.js";
import Link from "../atoms/Link.js";
import Socials from "../atoms/Socials.js";
import Copyright from "../atoms/Copyright.js";
import ContactForm from "../atoms/ContactForm.js";
import Resume from "../../documents/Ewa Nazim_Frontend Developer_CV.pdf";
import backgroundImg from "../../../src/img/header_bg.jpg";

const FooterWrapper = styled.footer`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 80px 15px 131px;
  background-image: url(${backgroundImg});
  background-position: center;
  background-size: cover;
  overflow: hidden;
`;

const ContactWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 300px;
  padding: 30px 15px;
  margin-bottom: 50px;
  background-color: ${colors.beige};
  box-shadow: 5px 5px 10px 2px ${colors.shadowbrown};

  @media ${queries.tablet} {
    padding: 50px;
    margin-bottom: 100px;
  }

  @media ${queries.laptop} {
    margin-bottom: 0px;
  }
`;

const ContactData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  margin: 30px 0 45px;
`;

const ContactFormWrapper = styled.div`
  height: 100%;

  & > div {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    height: 100%;
  }
`;

const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterWrapper id={t("menu.contact")}>
      <Container>
        <Row>
          <Col xs={12} lg={6}>
            <Slide triggerOnce delay={400}>
              <ContactWrapper>
                <SectionTitle text={t("footer.title")} />
                <ContactData>
                  <Text>{t("footer.contact.city")}</Text>
                  <Link
                    href={`mailto:${t("footer.contact.mail")}`}
                    target="_blank"
                  >
                    <Text>{t("footer.contact.mail")}</Text>
                  </Link>
                  <Socials />
                </ContactData>
                <CustomButton
                  text={t("footer.contact.downloadCV")}
                  delay={1200}
                  href={Resume}
                  download="Ewa Nazim_Frontend Developer_CV"
                />
              </ContactWrapper>
            </Slide>
          </Col>
          <Col xs={12} lg={6}>
            <ContactFormWrapper>
              <ContactForm />
            </ContactFormWrapper>
          </Col>
        </Row>
      </Container>
      <Copyright />
    </FooterWrapper>
  );
};

export default Footer;
