import React from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { Slide, Zoom } from "react-awesome-reveal";
import { queries } from "../../utils/queries.js";
import { colors } from "../../utils/colors.js";
import TapeImg from "../../img/tape.png";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  transition-duration: 0.3s;
  z-index: 2;

  &:hover {
    transform: scale(1.05);
  }

  &:hover .hoverBox {
    opacity: 0.85;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Image = styled.img`
  width: 180px;
  height: 100px;
  padding: 5px 5px 15px;
  background-color: ${colors.white};
  box-shadow: 5px 5px 10px 5px ${colors.shadow};

  @media ${queries.mobileL} {
    width: 250px;
    height: 140px;
    padding: 10px 10px 25px;
  }

  @media ${queries.tablet} {
    width: 350px;
    height: 220px;
    padding: 15px 15px 35px;
  }

  @media ${queries.laptop} {
    width: 300px;
    height: 180px;
  }
`;

const TapeWrapper = styled.div`
  position: absolute;
  width: 65px;
  transform: rotate(30deg);
  z-index: 2;

  &.tapeTop {
    top: 0;
    right: -15px;
  }

  &.tapeBottom {
    bottom: 0;
    left: -15px;
  }

  @media ${queries.tablet} {
    width: 100px;

    &.tapeTop {
    right: -30px;
  }

  &.tapeBottom {
    left: -30px;
  }
  }
`;
const TapeImage = styled.img`
  width: 100%;
`;

const HoverBox = styled.a`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: white;
  background-color: ${colors.brown};
  text-transform: uppercase;
  opacity: 0;
  transition: 0.5s ease;
`;

const ProjectTile = (props) => {
  const { t } = useTranslation();
  const data = props;
  return (
    <Zoom triggerOnce delay={data.delay}>
      <Wrapper>
        <TapeWrapper className="tapeTop">
          <Slide triggerOnce delay={data.delay + 200} direction="right">
            <TapeImage src={TapeImg} alt={t("projects.tapeAlt")} />
          </Slide>
        </TapeWrapper>
        <ImageWrapper $bgc={data.$bgc}>
          <Image src={data.img} alt="project photo"></Image>
        </ImageWrapper>
        <TapeWrapper className="tapeBottom">
          <Slide triggerOnce delay={data.delay + 300} direction="left">
            <TapeImage src={TapeImg} alt={t("projects.tapeAlt")} />
          </Slide>
        </TapeWrapper>
        <HoverBox
          data={data}
          className="hoverBox"
          href={data.link}
          target="_blank"
        >
          {data.name}
        </HoverBox>
      </Wrapper>
    </Zoom>
  );
};

export default ProjectTile;
