import React from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";
import { Heading, Text } from "../utils/typography.js";
import backgroundImg from "../../src/img/404.png";
import CustomButton from "../components/atoms/CustomButton.js";

const NotFoundWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  height: 100vh;
  width: 100%;
  background-image: url(${backgroundImg});
  background-position: center;
  background-size: cover;
  padding: 15px;
  text-align: center;
  overflow: hidden;

  & > div {
    position: relative;
  }
`;

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <NotFoundWrapper>
      <Fade cascade damping={0.6}>
        <Heading text={t("404.title")} />
        <Text size="1.2rem">{t("404.subtitle")}</Text>
        <CustomButton href="/" target="_self" text={t("404.linkTitle")} />
      </Fade>
    </NotFoundWrapper>
  );
}
