export const colors = {
  beigelight: "#EADEC5",
  beige: "#D6C6A8",
  brownlight: "#CBA07D",
  brown: "#563913",
  browndarker: "#452a08",
  mintlight: "#D9E4DC",
  mint: "#C3D4C5",
  mintdark: "#91A989",
  mintdarker: "#4a5e4c",
  mint500: "rgba(145, 169, 137, 0.8)",
  green: "#425D36",
  white: "#FFF",
  black: "#000",
  shadow: "rgba(0,0,0,0.2)",
  shadowbrown: "rgba(86, 57, 19, 0.2)",
  menuShadow: "rgba(0,0,0,0.85)"
};
