import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { styled } from "styled-components";
import { colors } from "../../utils/colors.js";
import { queries } from "../../utils/queries.js";
import { languages } from "../../utils/languages.js";

const LanguagePickerWrapper = styled.div`
  div {
    border: none;
    background-color: transparent;

    @media ${queries.laptop} {
      background: ${colors.mint500};
    }
  }

  button,
  button:hover,
  button:active,
  button:focus-visible {
    background: none !important;
    border: none;
    color: ${colors.mint} !important;
    outline: none;

    @media ${queries.laptop} {
      color: ${colors.white} !important;
    }
  }

  a {
    color: ${colors.white};
    font-weight: 400;
    width: 60px;

    &:hover {
      background: none;
      color: ${colors.mint};
      font-weight: 600;
    }

    @media ${queries.laptop} {
      color: ${colors.white};
      width: 60px;

      &:hover {
        color: ${colors.beigelight};
        font-weight: 400;
      }
    }
  }

  @media ${queries.laptop} {
    select {
      color: ${colors.mint};
    }
  }
`;

const LanguagePicker = ({ lang, handleLanguageChange }) => {
  const filteredLanguages = languages.filter(
    (item) => item.value !== lang && item.value !== "es"
  );
  return (
    <LanguagePickerWrapper>
      <Dropdown title="Select language" onChange={handleLanguageChange}>
        <Dropdown.Toggle id="dropdown-basic">
          {lang.toUpperCase()}
        </Dropdown.Toggle>
        <Dropdown.Menu className="drop-menu">
          {filteredLanguages.map((item) => {
            return (
              <Dropdown.Item key={item.value} href={`?lng=${item.value}`}>
                {item.value.toUpperCase()}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </LanguagePickerWrapper>
  );
};

export default LanguagePicker;
