import React from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-awesome-reveal";
import { queries } from "../../utils/queries.js";
import { colors } from "../../utils/colors.js";
import { SubHeading, Text } from "../../utils/typography.js";
import FrameImg from "../../img/skills_frame.png";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  max-width: 100%;
  padding: 50px 15px;
  background-color: ${(props) => (props?.$bgc ? props.$bgc : colors.white)};
  transform: ${(props) =>
    props?.$rotation ? `rotate(${props.$rotation})` : "rotate(0deg)"};

  &:hover .frame {
    opacity: 1;
  }

  @media ${queries.tablet} {
    width: 500px;
    height: 300px;
  }

  @media ${queries.laptopL} {
    width: 415px;
  }
`;

const TitleWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 50%;
  font-family: "GreatVibes", san-serif;
  transform: translate(-50%, -50%);
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
`;

const Frame = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transform: translate(-15px, -15px);
  transition: 0.3s ease;
  opacity: 0;

  @media ${queries.tablet} {
    transform: translate(-30px, -30px);
  }
`;

const SkillTile = (props) => {
  const { t } = useTranslation();
  const data = props;
  return (
    <Zoom triggerOnce delay={data.delay}>
      <Wrapper $rotation={data.$rotation} $bgc={data.$bgc}>
        <TitleWrapper>
          <SubHeading text={data.title} />
        </TitleWrapper>
        <DataWrapper data={data}>
          {typeof data.data === "string" ? (
            <Text>{data.data}</Text>
          ) : (
            data.data.map((language) => (
              <div key={language}>
                <Text>{language}</Text>
              </div>
            ))
          )}
        </DataWrapper>
        <Frame src={FrameImg} className="frame" alt={t("skills.frameAlt")} />
      </Wrapper>
    </Zoom>
  );
};

export default SkillTile;
