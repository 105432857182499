import React from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import { Container, Row, Col } from "react-bootstrap";
import { queries } from "../../utils/queries.js";
import { colors } from "../../utils/colors.js";
import { SectionTitle, Span, Text } from "../../utils/typography.js";
import myPortraitImg from "../../img/selfportrait.png";
import TapeImg from "../atoms/TapeImg.js";
import MonsteraImg from "../../img/about_monstera.png";

const AboutWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 80px 15px;
  background-color: ${colors.mint};
  overflow: hidden;
  z-index: 3;

  @media ${queries.tablet} {
    min-height: 100vh;
  }
`;

const MonsteraWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 400px;

  @media ${queries.tablet} {
    bottom: 80px;
  }

  @media ${queries.laptop} {
    height: 500px;
  }

  @media ${queries.laptopL} {
    height: 600px;
  }

  @media ${queries.desktop} {
    height: 900px;
  }
`;

const MonsteraImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 400px;
  margin: 0 0 0 auto;

  @media ${queries.tablet} {
    max-height: 450px;
  }

  @media ${queries.laptop} {
    max-height: 550px;
  }

  @media ${queries.laptopL} {
    max-height: 650px;
  }

  @media ${queries.desktop} {
    max-height: 850px;
  }
`;

const TextWrapper = styled.div`
  @media ${queries.laptop} {
    padding: 0 40px;
  }
`;

const DescriptionWrapper = styled.div`
  position: relative;
  padding: 15px;
  margin-bottom: 35px;
  text-align: center;
  background-color: ${colors.beigelight};

  @media ${queries.tablet} {
    padding: 20px;
  }

  @media ${queries.laptop} {
    padding: 40px;
    margin-top: 50px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: auto;
  z-index: 2;
`;

const MeImg = styled.img`
  position: relative;
  display: block;
  width: 100%;
  padding: 20px 20px 50px;
  margin: auto;
  background-color: ${colors.white};
  box-shadow: 8px 8px 10px 2px ${colors.shadow};
  transition-duration: 0.3s;
  z-index: 1;

  @media ${queries.tablet} {
    padding: 35px 35px 80px;
  }

  &:hover{
    transform: scale(1.05);
  }
`;

const About = () => {
  const { t } = useTranslation();

  return (
    <AboutWrapper id={t("menu.about")}>
      <MonsteraWrapper>
        <Slide triggerOnce direction="up" delay={400}>
          <MonsteraImage src={MonsteraImg} alt={t("about.monsteraAlt")} />
        </Slide>
      </MonsteraWrapper>
      <Container>
        <Row>
          <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 0 }} lg={5}>
            <ImageWrapper>
              <Zoom triggerOnce delay={1200}>
                <MeImg src={myPortraitImg} alt="Ewa Nazim" />
              </Zoom>
              <TapeImg orientation="top" delay={1500} direction="right" />
              <TapeImg orientation="bottom" delay={1600} direction="left" />
            </ImageWrapper>
          </Col>
          <Col xs={{ span: 12, order: 0 }} md={{ span: 6, order: 1 }} lg={7}>
            <TextWrapper>
              <Fade triggerOnce delay={400}>
                <SectionTitle text={t("about.title")} />
              </Fade>
              <Slide triggerOnce direction="right" delay={600}>
                <DescriptionWrapper>
                  <Text>
                    {t("about.description1")}
                    <Span
                      text={t("about.frontend")}
                      color={colors.green}
                      $decoration="italic"
                    />
                    {t("about.description2")}
                    <Span
                      text={t("about.graphics")}
                      color={colors.green}
                      $decoration="italic"
                    />
                    {t("about.description3")}
                  </Text>
                </DescriptionWrapper>
              </Slide>
            </TextWrapper>
          </Col>
        </Row>
      </Container>
    </AboutWrapper>
  );
};

export default About;
