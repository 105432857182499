import React from "react";
import { Zoom } from "react-awesome-reveal";
import { styled } from "styled-components";
import { queries } from "../../utils/queries.js";
import { colors } from "../../utils/colors.js";
import { SubHeading, Text } from "../../utils/typography.js";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
  background-position: center;
  background-size: cover;
  overflow: hidden;

  @media ${queries.laptop} {
    flex-direction: row;
    gap: 35px;
  }
`;

const Number = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  padding: 40px;
  border-radius: 50%;
  background-color: ${(props) => (props?.$bgc ? props.$bgc : "white")};
  color: ${colors.brownlight};
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media ${queries.laptop} {
    align-items: start;
    text-align: left;
  }
`;

const CompanyData = (props) => {
  const data = props;
  return (
    <Zoom triggerOnce delay={data.delay}>
      <Wrapper>
        <Number $bgc={data.$bgc}>
          <SubHeading text={data.id} />
        </Number>
        <DataWrapper data={data}>
          <Text size="16px">{data.title}</Text>
          <Text $decoration="italic">{data.period}</Text>
          <Text $weight="bold">{data.position}</Text>
        </DataWrapper>
      </Wrapper>
    </Zoom>
  );
};

export default CompanyData;
