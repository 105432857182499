import React from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { Fade, Zoom } from "react-awesome-reveal";
import { queries } from "../../utils/queries.js";
import { colors } from "../../utils/colors.js";
import { Text } from "../../utils/typography.js";
import TapeImg from "../../img/tape.png";

const StickerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px 0px;
  overflow: hidden;
  transition-duration: 0.3s;
  z-index: 3;

  @media ${queries.laptopL} {
    width: 45%;
  }

  &:hover{
    transform: scale(1.1);
  }
`;

const TapeWrapper = styled.div`
  height: 20px;
  z-index: 2;
`;

const TapeImage = styled.img`
  width: 200px;
  max-width: 100%;
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100%;
  min-height: 150px;
  padding: 30px;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  background-color: ${(props) => (props?.$bgc ? props.$bgc : "white")};
  transform: ${(props) =>
    props?.$rotation ? `rotate(${props.$rotation})` : "rotate(0deg)"};
  box-shadow: 4px 4px 10px 2px ${colors.shadow};
  border-radius: 2px;
`;

const HobbiesSticker = (props) => {
  const { t } = useTranslation();
  const data = props;
  return (
    <StickerWrapper id={data.id}>
      <TapeWrapper>
        <Fade triggerOnce direction="down" delay={data.delay + 200}>
          <TapeImage src={TapeImg} alt={t("hobbies.tapeAlt")} />
        </Fade>
      </TapeWrapper>
      <ContentWrapper>
        <Zoom triggerOnce delay={data.delay}>
          <TextWrapper $bgc={data.$bgc} $rotation={data.$rotation}>
            <Text>{data.title}</Text>
          </TextWrapper>
        </Zoom>
      </ContentWrapper>
    </StickerWrapper>
  );
};

export default HobbiesSticker;
