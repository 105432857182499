import React, { useRef } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import emailjs from "@emailjs/browser";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { queries } from "../../utils/queries.js";
import { Text } from "../../utils/typography";
import CustomButton from "./CustomButton.js";
import { colors } from "../../utils/colors.js";
import { Fade, Zoom } from "react-awesome-reveal";
import TapeImg from "../../img/footer_tape.png";

const FormWrapper = styled.div`
  position: relative;
  padding: 30px 15px;
  background-color: ${colors.mintdark};
  box-shadow: 5px 5px 10px 2px ${colors.shadowbrown};

  button {
    display: block;
    margin: 45px auto 0;
  }

  @media ${queries.tablet} {
    padding: 50px;
  }
`;

const TapeWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
`;

const TapeImage = styled.img`
  position: absolute;
  top: 0px;
  left: 50%;
  width: 60%;
  transform: translate(-50%, -50%);
`;

const ContactForm = () => {
  const form = useRef();
  const { t } = useTranslation();

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
    isSubmitting,
  } = useFormik({
    initialValues: {
      from_name: "",
      reply_to: "",
      message: "",
    },
    validationSchema: Yup.object().shape({
      from_name: Yup.string()
        .required(t("contactForm.noName"))
        .matches(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u, t("contactForm.wrongName")),
      reply_to: Yup.string()
        .email(t("contactForm.wrongMail"))
        .required(t("contactForm.noMail")),
      message: Yup.string().required(t("contactForm.noMessage")),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      try {
        emailjs
          .send(
            "service_agykfsl",
            "template_022gghf",
            values,
            "bq7-aRt8vZaUqfOwq"
          )
          .then(() => {
            setSubmitting(false);
            resetForm();
            alert(t("contactForm.message_success"));
          });
      } catch {
        setSubmitting(false);
        alert(t("contactForm.message_error"));
      }
    },
  });

  return (
    <Zoom triggerOnce delay={1000} height="100%">
      <FormWrapper id="contact-form">
        <TapeWrapper>
          <Fade triggerOnce delay={2000}>
            <TapeImage src={TapeImg} alt={t("contactForm.tapeAlt")} />
          </Fade>
        </TapeWrapper>
        <form ref={form} onSubmit={handleSubmit}>
          <label htmlFor="from_name">{t("contactForm.name")}</label>
          <input
            type="text"
            id="from_name"
            name="from_name"
            placeholder={t("contactForm.name")}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.from_name}
          />
          {touched.from_name && errors.from_name && (
            <Text>{errors.from_name}</Text>
          )}
          <label htmlFor="reply_to">{t("contactForm.email")}</label>
          <input
            type="email"
            id="reply_to"
            name="reply_to"
            placeholder="example@email.com"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.reply_to}
          />
          {touched.reply_to && !!errors.reply_to && (
            <Text>{errors.reply_to}</Text>
          )}
          <label htmlFor="message">{t("contactForm.message")}</label>
          <textarea
            name="message"
            id="message"
            placeholder={t("contactForm.message") + "..."}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.message}
          />
          {touched.message && !!errors.message && <Text>{errors.message}</Text>}
          <CustomButton
            disabled={isSubmitting}
            option="secondary"
            text={t("contactForm.send")}
            type="submit"
            delay={1400}
          />
        </form>
      </FormWrapper>
    </Zoom>
  );
};

export default ContactForm;
