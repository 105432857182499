import React from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { Slide, Zoom } from "react-awesome-reveal";
import { queries } from "../../utils/queries.js";
import { Heading } from "../../utils/typography.js";
import Profession from "../atoms/Profession.js";
import Socials from "../atoms/Socials.js";
import backgroundImg from "../../../src/img/header_bg.jpg";
import noteImg from "../../../src/img/note.png";
import leafImg from "../../../src/img/header_leaf.png";
import monsteraImg from "../../../src/img/header_monstera.png";

const HeaderWrapper = styled.header`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-image: url(${backgroundImg});
  background-position: center;
  background-size: cover;
  overflow: hidden;
`;
const LeafWrapper = styled.div`
  position: absolute;
  top: -50px;
  left: 15px;
  display: block;
  width: 200px;

  @media ${queries.tablet} {
    top: -140px;
    left: 50px;
    width: 400px;
  }
`;
const LeafImg = styled.img`
  display: block;
  width: 200px;
  max-width: 100%;

  @media ${queries.tablet} {
    width: 450px;
  }
`;

const MonsteraWrapper = styled.div`
  position: absolute;
  bottom: -55px;
  right: 15px;
  display: block;

  @media ${queries.tablet} {
    width: 350px;
    right: 50px;
  }
  @media ${queries.laptop} {
    width: 500px;
  }
`;
const MonsteraImg = styled.img`
  width: 250px;
  height: auto;
  z-index: 0;

  @media ${queries.tablet} {
    width: 350px;
  }

  @media ${queries.laptop} {
    width: 500px;
  }
`;

const NoteWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  transition-duration: 0.3s;
  z-index: 2;

  div {
    z-index: 2;
  }

  &:hover{
    transform: scale(1.1);
  }
`;

const NoteImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-height: 90vh;
  padding: 10px;
  transform: translate(-50%, -55%);
  transform-origin: 0 0;
  animation: RevealNote 0.9s 0.3s linear;
  z-index: 1;
`;

const Header = () => {
  const { t } = useTranslation();

  return (
    <HeaderWrapper id={t("menu.home")}>
      <LeafWrapper>
        <Slide triggerOnce direction="down" delay={800}>
          <LeafImg src={leafImg} alt={t("header.leafAlt")} />
        </Slide>
      </LeafWrapper>
      <MonsteraWrapper>
        <Slide triggerOnce direction="up" delay={1200}>
          <MonsteraImg src={monsteraImg} alt={t("header.monsteraAlt")} />
        </Slide>
      </MonsteraWrapper>
      <NoteWrapper>
        <NoteImg src={noteImg} alt={t("header.noteAlt")} />
        <Zoom triggerOnce fraction={1} delay={400} duration={1200}>
          <Heading text={t("header.title")} />
        </Zoom>
        <Profession />
        <Socials delay={3000}/>
      </NoteWrapper>
    </HeaderWrapper>
  );
};

export default Header;
