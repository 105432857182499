import React from "react";
import { styled } from "styled-components";
import { queries } from "../../utils/queries.js";

const ProfessionWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 50px;
  z-index: 2;

  @media ${queries.tablet} {
  }

  #profession-svg {
    stroke-dasharray: 1204;
    stroke-dashoffset: 0;
    animation: 4s fillSvg ease-in-out;
    z-index: 2;
  }

  @keyframes fillSvg {
    0% {
      stroke-dashoffset: 1204;
    }
    40% {
      stroke-dashoffset: 1204;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
`;

const Profession = () => {
  return (
    <ProfessionWrapper>
      <svg
        id="profession-svg"
        width="100%"
        height="100%"
        viewBox="0 0 868 278"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="100%" height="100%" fill="transparent" />
        <path
          d="M102 50C88.0209 63.9791 64.9369 62 46.5 62C39.8803 62 43.9602 46.8224 59 56.2222C73.3681 65.2023 49.844 132.05 41 142C40.1227 142.987 24.2968 173.586 22.0555 163.5C18.9277 149.425 38.9941 147.411 44.2222 138C47.8006 131.559 70.4897 112.392 77.5 118C81.7788 121.423 71.993 152.016 82 129.5C87.1876 117.828 126.81 106.746 117.778 115.778C109.87 123.685 109.441 140 125.5 140C139.169 140 149 137.835 149 122C149 110.796 125.123 114 118 114C106.956 114 147.508 106.467 151 116.944C155.949 131.793 150.822 144.18 161.778 125.556C171.266 109.425 184 117.159 184 132.5C184 152.575 213.32 112.332 216.5 108.444C222.109 101.589 229 89.0293 229 80C229 75.5533 220.771 60.4437 220 67C218.31 81.3622 216 95.8381 216 111.5C216 122.707 213.044 139 228 139C240.662 139 266 132.603 266 115.5C266 97.7578 246 115.264 246 128C246 142.498 261.966 141.371 269.5 133C270.568 131.813 291.879 99.9439 293 119C293.648 130.014 289.797 154.062 298.222 133C315.31 90.2802 319.993 143 332 143C347.73 143 355.695 141.254 366.556 131.944C377.322 122.716 366.957 105 355.556 105C333.991 105 336.226 161.663 365.444 132.444C377.681 120.208 375 76.6359 375 60"
          stroke="#8F6C52"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M462 105C460.479 92.8328 401.094 135.905 443 138C462.474 138.974 490.532 93.0254 470.5 77C466.895 74.1158 467 102.003 467 106C467 110.994 466.126 134.393 471.556 135.944C485.036 139.796 493.896 126.66 501.778 118.778C517.285 103.271 494 102.803 494 116C494 133.92 507.878 132.102 518.556 126C524.105 122.829 524.889 95.9996 528 124C530.287 144.585 547.121 116.503 551.5 113C554.741 110.407 577.656 116.027 584.556 114.056C597 110.5 574.433 96.3514 567.778 107C563.101 114.483 562.086 131.268 576 132C594.345 132.966 612 95.0351 612 79.5C612 59.3383 604.162 69.9245 605 85C605.585 95.5316 610 105.656 610 116.5C610 153.561 641.811 104 650 104C681.763 104 660.384 144.23 639.222 117.778C631.745 108.431 707 95.5434 707 116.5C707 131.958 687.75 137.305 676.222 125.778C672.112 121.667 681 105.199 681 110.222C681 122.319 680 134.516 680 146.5C680 158.268 676.011 169.691 674.056 180.444C672.901 186.797 662.324 181.69 665.222 174.444C674.1 152.249 700.949 144.764 713.778 127C719.182 119.517 744.527 110.079 723.444 104.056C712.39 100.897 714.021 144.355 732 128.944C742.62 119.842 747.743 101.813 761 117.556C766.404 123.973 760.287 132.614 765.556 116.056C768.24 107.62 778.281 109.813 784 106"
          stroke="#8F6C52"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M36 98C47.3097 90.8029 59.1017 88 72 88"
          stroke="#8F6C52"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M209 96C218.073 96 226.984 94.0607 236 93"
          stroke="#8F6C52"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
    </ProfessionWrapper>
  );
};
export default Profession;
