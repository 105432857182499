import React from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { Fade, Slide } from "react-awesome-reveal";
import { Container, Row, Col } from "react-bootstrap";
import { queries } from "../../utils/queries.js";
import { colors } from "../../utils/colors.js";
import { SectionTitle } from "../../utils/typography.js";
import MonsteraImg from "../../img/hobbies_monstera.png";
import PaperImg from "../../img/hobbies_paper.png";
import NoteImg from "../../img/hobbies_note.png";
import HobbiesSticker from "../atoms/HobbiesSticker.js";

const HobbiesWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 80px 15px 500px;
  background-color: ${colors.mintlight};
  overflow: hidden;
  z-index: 3;

  @media ${queries.tablet} {
    min-height: 100vh;
    padding: 80px 15px 450px;
  }

  @media ${queries.laptop} {
    padding: 80px 15px;
  }
`;

const MonsteraWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  left: 20px;
  display: block;
  width: 250px;
  height: 400px;
  z-index: 4;

  @media ${queries.tablet} {
    height: 450px;
  }

  @media ${queries.laptop} {
    height: 700px;
    width: 50%;
  }

  @media ${queries.laptopL} {
    height: 600px;
  }

  @media ${queries.desktop} {
    height: 900px;
  }
`;

const MonsteraImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 400px;
  margin-bottom: 0;
  transition-duration: 0.3s;

  @media ${queries.tablet} {
    max-height: 450px;
  }

  @media ${queries.laptop} {
    max-height: 700px;
  }

  @media ${queries.laptopL} {
    max-height: 600px;
  }

  @media ${queries.desktop} {
    max-height: 850px;
  }

  &:hover {
    transform: scale(1.05);
  }
`;

const BackgroundWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  margin-bottom: 50px;
  z-index: 3;

  & > div {
    width: 100%;
    height: 100%;
  }

  .hobbies-image {
    position: absolute;
    display: block;
    width: 100%;
    max-width: 420px;
    margin: 0 auto;

    @media ${queries.tablet} {
      max-width: 600px;
    }

    @media ${queries.laptop} {
      width: 550px;
    }

    @media ${queries.laptopL} {
      width: 650px;
    }
  }

  @media ${queries.mobileM} {
    margin-bottom: 100px;
  }
  @media ${queries.tablet} {
    margin-bottom: 350px;
  }

  @media ${queries.laptop} {
    height: 100%;
    margin-bottom: 0px;
  }
`;
const NoteImage = styled.img`
  top: 0;
  left: 50%;
  transform: rotate(3deg) translate(-50%, 0%);
  z-index: 1;

  @media ${queries.laptop} {
    top: 50%;
    transform: rotate(3deg) translate(-50%, 40%);
  }

  @media ${queries.laptopL} {
    top: 0;
  }
`;
const PaperImage = styled.img`
  top: 0px;
  left: 40%;
  transform: rotate(-2deg) translate(-50%, -55%);
  z-index: 2;

  @media ${queries.tablet} {
    top: 50%;
    left: 50%;
    transform: rotate(-2deg) translate(-50%, -50%);
  }

  @media ${queries.laptop} {
    top: -80%;
    left: 50%;
  }
`;

const StickersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;

  @media ${queries.laptopL} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const Hobbies = () => {
  const { t } = useTranslation();

  const hobbiesData = [
    {
      id: 1,
      title: t("hobbies.travelling"),
      $bgc: colors.beigelight,
      $rotation: "-1deg",
      delay: 800,
    },
    {
      id: 2,
      title: t("hobbies.graphics"),
      $bgc: colors.beige,
      $rotation: "2deg",
      delay: 900,
    },
    {
      id: 3,
      title: t("hobbies.manual"),
      $bgc: colors.white,
      $rotation: "-2deg",
      delay: 1000,
    },
    {
      id: 4,
      title: t("hobbies.dancing"),
      $bgc: colors.mint,
      $rotation: "0deg",
      delay: 1100,
    },
    {
      id: 5,
      title: t("hobbies.dog"),
      $bgc: colors.beigelight,
      $rotation: "1deg",
      delay: 1200,
    },
    {
      id: 6,
      title: t("hobbies.skills"),
      $bgc: colors.white,
      $rotation: "-2deg",
      delay: 1300,
    },
    {
      id: 7,
      title: t("hobbies.plants"),
      $bgc: colors.beige,
      $rotation: "2deg",
      delay: 1400,
    },
  ];

  return (
    <HobbiesWrapper id={t("menu.hobbies")}>
      <MonsteraWrapper>
        <Slide triggerOnce direction="up" delay={800}>
          <MonsteraImage src={MonsteraImg} alt={t("hobbies.monsteraAlt")} />
        </Slide>
      </MonsteraWrapper>
      <Container>
        <Row>
          <Col xs={12} md={12} lg={6}>
            <BackgroundWrapper>
              <Slide
                triggerOnce
                direction="down"
                delay={500}
                style={{ order: 1, zIndex: 1 }}
              >
                <NoteImage
                  src={NoteImg}
                  className="hobbies-image"
                  alt={t("hobbies.noteAlt")}
                />
              </Slide>
              <Slide
                triggerOnce
                direction="left"
                delay={600}
                style={{ order: 3, zIndex: 2 }}
              >
                <PaperImage
                  src={PaperImg}
                  className="hobbies-image"
                  alt={t("hobbies.paperAlt")}
                />
              </Slide>
              <Fade triggerOnce delay={1200} style={{ order: 2, zIndex: 3 }}>
                <SectionTitle text={t("hobbies.title")} />
              </Fade>
            </BackgroundWrapper>
          </Col>
          <Col xs={12} md={12} lg={6}>
            <StickersWrapper>
              {hobbiesData.map((hobby) => (
                <HobbiesSticker key={hobby.id} {...hobby} />
              ))}
            </StickersWrapper>
          </Col>
        </Row>
      </Container>
    </HobbiesWrapper>
  );
};

export default Hobbies;
