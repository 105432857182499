import React from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { colors } from "../utils/colors";
import Topbar from "../components/modules/TopBar";
import Header from "../components/modules/Header";
import About from "../components/modules/About";
import Hobbies from "../components/modules/Hobbies";
import Exp from "../components/modules/Exp";
import Projects from "../components/modules/Projects";
import Skills from "../components/modules/Skills";
import Footer from "../components/modules/Footer";
import CookieConsent from "react-cookie-consent";

const HomeWrapper = styled.div``;

const Home = ({ lang, handleLanguageChange }) => {
  const { t } = useTranslation();

  return (
    <HomeWrapper>
      <Topbar lang={lang} handleLanguageChange={handleLanguageChange} />
      <Header />
      <About />
      <Exp />
      <Projects />
      <Skills />
      <Hobbies />
      <Footer />
      <CookieConsent
        location="bottom"
        cookieName="myAwesomeCookieName"
        buttonText={t("cookies.confirm")}
        style={{
          background: colors.mintdarker,
          color: colors.white,
          fontFamily: "Caudex",
          fontSize: "14px",
          padding: "15px"
        }}
        buttonStyle={{
          background: colors.white,
          color: colors.green,
          fontSize: "13px",
          borderRadius: "3px"
        }}
        overlay
      >
        {t("cookies.alert")}
      </CookieConsent>
    </HomeWrapper>
  );
};

export default Home;
