import React from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import { Container, Row, Col } from "react-bootstrap";
import { queries } from "../../utils/queries.js";
import { colors } from "../../utils/colors.js";
import { SectionTitle } from "../../utils/typography.js";
import MonsteraImg from "../../img/projects_monstera.png";
import TapeImg from "../../img/projects_tape.png";
import PageImg from "../../img/projects_page.png";
import ProjectBaImg from "../../img/project_ba.png";
import ProjectGcImg from "../../img/project_gc.png";
import ProjectFnImg from "../../img/project_fn.png";
import ProjectTile from "../atoms/ProjectTile.js";

const ProjectsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 80px 15px;
  background: linear-gradient(120deg, ${colors.mint}, ${colors.beigelight});
  overflow: hidden;
  z-index: 3;
`;

const TitleWrapper = styled.div`
  position: relative;
  height: 400px;
  width: 100%;
  margin-bottom: 50px;

  @media ${queries.tablet} {
    height: 600px;
  }

  @media ${queries.laptop} {
    height: 100%;
    margin: 0;
  }
`;

const MonsteraWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 100%;
  max-height: 400px;
  transform: translate(-50%, -50%);

  @media ${queries.tablet} {
    max-height: 600px;
  }
`;

const MonsteraImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 400px;
  margin: auto;

  @media ${queries.tablet} {
    max-height: 600px;
  }
`;

const TapeWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 2;
`;

const TextWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  z-index: 3;
`;

const TapeImage = styled.img`
  width: 100%;
`;

const ProjectsDataWrapper = styled.div`
  position: relative;
`;

const ProjectsImages = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 25px;
  width: 100%;
  height: 100%;
  padding: 40px 20px 40px 50px;
  max-width: 600px;
`;

const PageImage = styled.img`
  width: 100%;
  box-shadow: 12px 12px 10px 0px ${colors.shadowbrown};
  margin: 0 auto;
  display: block;

  @media ${queries.tablet} {
    max-width: 600px;
  }
`;

const Projects = () => {
  const { t } = useTranslation();

  const projectsData = [
    {
      id: t("projects.websites.1.id"),
      name: t("projects.websites.1.name"),
      link: t("projects.websites.1.link"),
      img: ProjectBaImg,
      delay: 1400,
    },
    {
      id: t("projects.websites.2.id"),
      name: t("projects.websites.2.name"),
      link: t("projects.websites.2.link"),
      img: ProjectGcImg,
      delay: 1600,
    },
    {
      id: t("projects.websites.3.id"),
      name: t("projects.websites.3.name"),
      link: t("projects.websites.3.link"),
      img: ProjectFnImg,
      delay: 1800,
    }
  ];

  return (
    <ProjectsWrapper id={t("menu.projects")}>
      <Container>
        <Row>
          <Col xs={12} md={12} lg={6}>
            <TitleWrapper>
              <MonsteraWrapper>
                <Zoom triggerOnce delay={400}>
                  <MonsteraImage
                    src={MonsteraImg}
                    alt={t("projects.monsteraAlt")}
                  />
                </Zoom>
              </MonsteraWrapper>
              <TapeWrapper>
                <Fade triggerOnce delay={800}>
                  <TapeImage src={TapeImg} alt={t("projects.tapeAlt")} />
                </Fade>
              </TapeWrapper>
              <TextWrapper>
                <SectionTitle text={t("projects.title")} $index={3} />
              </TextWrapper>
            </TitleWrapper>
          </Col>
          <Col xs={12} md={12} lg={6}>
            <ProjectsDataWrapper>
              <Slide triggerOnce direction="right" delay={1000}>
                <PageImage src={PageImg} alt={t("projects.paperAlt")} />
              </Slide>
              <ProjectsImages>
                {projectsData.map((project) => (
                  <ProjectTile key={project.id} {...project} />
                ))}
              </ProjectsImages>
            </ProjectsDataWrapper>
          </Col>
        </Row>
      </Container>
    </ProjectsWrapper>
  );
};

export default Projects;
