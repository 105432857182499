import React from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";
import { queries } from "../../utils/queries.js";
import tapeImg from "../../img/tape.png";

const TapeWrapper = styled.div`
  position: absolute;
  top: ${(props) => (props.orientation === "top" ? "10px" : "unset")};
  bottom: ${(props) => (props.orientation === "bottom" ? "10px" : "unset")};
  right: ${(props) => (props.orientation === "top" ? "-35px" : "unset")};
  left: ${(props) => (props.orientation === "bottom" ? "-25px" : "unset")};

  @media ${queries.tablet} {
    top: ${(props) => (props.orientation === "top" ? "30px" : "unset")};
    bottom: ${(props) => (props.orientation === "bottom" ? "30px" : "unset")};
    right: ${(props) => (props.orientation === "top" ? "-75px" : "unset")};
    left: ${(props) => (props.orientation === "bottom" ? "-65px" : "unset")};
  }
`;

const Tape = styled.img`
  width: 100px;
  transform: rotate(45deg);

  @media ${queries.tablet} {
    width: 200px;
  }
`;

const TapeImg = ({ orientation, delay, direction }) => {
  const { t } = useTranslation();
  return (
    <TapeWrapper orientation={orientation}>
      <Fade triggerOnce delay={delay} direction={direction} fraction={0}>
        <Tape src={tapeImg} alt={t("about.tapeAlt")} />
      </Fade>
    </TapeWrapper>
  );
};

export default TapeImg;
