import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./App.css";
import Home from "./pages/index.js";
import NotFound from "./pages/404.js";

function App() {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.resolvedLanguage);

  const handleLanguageChange = (e) => {
    setLang(e.target.value);
    let loc = "https://www.ewanazim.pl";
    window.location.replace(loc + "?lng=" + e.target.value);
  };

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <Home lang={lang} handleLanguageChange={handleLanguageChange} />
          }
        />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
