import React from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { colors } from "../../utils/colors.js";
import { FaLinkedin } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa";
import { Fade } from "react-awesome-reveal";
import Link from "../atoms/Link.js";

const SocialsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 30px;

  svg {
    fill: ${colors.brown};
  }
`;

const Socials = ({delay}) => {
  const { t } = useTranslation();
  return (
    <SocialsWrapper>
      <Fade triggerOnce cascade fraction={1} delay={delay} duration={1000}>
        <Link href={t("footer.contact.linkedin")} target="_blank">
          <FaLinkedin />
        </Link>
        <Link href={t("footer.contact.github")} target="_blank">
          <FaGithub />
        </Link>
      </Fade>
    </SocialsWrapper>
  );
};

export default Socials;
