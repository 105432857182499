import React from "react";
import { styled } from "styled-components";
import { colors } from "../../utils/colors.js";

const LinkWrapper = styled.a`
  color: ${colors.brown};
  outline: none;
  white-space: nowrap;
`;

const Link = ({ href, children, target }) => {
  return (
    <LinkWrapper href={href} target={target}>
      {children}
    </LinkWrapper>
  );
};

export default Link;
